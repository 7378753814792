<template>
  <div class="json-viewer container-fluid mt-4" @drop.prevent="onDrop" @dragover.prevent>
    <!-- Botón para alternar entre vista de datos JSON y mosaico de imágenes
    <div class="d-flex justify-content-end mb-4">
      <button class="btn btn-secondary" >
        {{ showMosaic ? 'Ver datos JSON' : 'Ver imágenes en mosaico' }}
      </button>
    </div> -->

    <!-- Mostrar el componente de mosaico de imágenes o los datos JSON -->
    <image-mosaic v-if="showMosaic" :base64Images="base64Images" @click="toggleView" />

    <div v-else>
      <div v-if="!filteredJson" class="dropzone text-center py-3 border border-secondary">
        <p>Arrastra y suelta un archivo JSON aquí, o haz clic para seleccionar uno</p>
        <input type="file" ref="fileInput" @change="onFileChange" style="display: none;" />
        <button class="btn btn-primary mt-2" @click="selectFile">Seleccionar archivo JSON</button>
      </div>

      <div class="row mt-5">
        <!-- Columna de imágenes -->
        <div class="col-md-6">
          <h3 class="text-primary">Imágenes</h3>
          <div v-if="base64Images.length" class="d-flex flex-column align-items-start">
            <div v-for="(image, index) in base64Images" :key="index" class="mb-3">
              <img :src="image.src" alt="Imagen Base64" class="img-fluid img-thumbnail h-100" style="max-width: 100%;"
                @click="handleImageClick($event, image.matricula)"
              />
            </div>
          </div>
          <p v-else>No hay imágenes en este archivo JSON.</p>
        </div>

        <!-- Columna de datos JSON en formato de tabla -->
        <div class="col-md-6">
          <h3 class="text-primary">Datos JSON</h3>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Campo</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, key) in filteredJson" :key="key">
                <td>{{ key }}</td>
                <td>
                  <span v-if="typeof value === 'string' || typeof value === 'number'">{{ value }}</span>
                  <pre v-else>{{ JSON.stringify(value, null, 2) }}</pre>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageMosaic from './ImageMosaic.vue';

export default {
  components: {
    ImageMosaic
  },
  data() {
    return {
      showMosaic: false,
      jsonContent: null,
      filteredJson: null,
      base64Images: [],
    };
  },
  methods: {
    toggleView() {
      this.showMosaic = !this.showMosaic;
    },
    onDrop(event) {
      const file = event.dataTransfer.files[0];
      this.readJsonFile(file);
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.readJsonFile(file);
    },
    readJsonFile(file) {
      if (file && file.type === 'application/json') {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const json = JSON.parse(e.target.result);
            this.jsonContent = json;
            this.filteredJson = JSON.parse(JSON.stringify(json)); // Crear una copia del JSON original
            this.extractBase64Images(this.filteredJson);
          } catch (error) {
            alert('Error al leer el archivo JSON');
          }
        };
        reader.readAsText(file);
      } else {
        alert('Por favor, selecciona un archivo JSON válido.');
      }
    },
    extractBase64Images(json) {
      this.base64Images = [];

      // Función recursiva para buscar imágenes Base64 en el JSON
      const findBase64Images = (obj) => {
        for (const key in obj) {
          if (typeof obj[key] === 'string' && obj[key].startsWith('/9j/')) {
            // Suponiendo que la matrícula está disponible en algún lugar cercano en el JSON
            const matricula = obj.matricula || 'sin_matricula'; // Asegúrate de tener una forma de obtener la matrícula
            this.base64Images.push({ src: `data:image/jpeg;base64,${obj[key]}`, matricula });
            // Eliminar la cadena Base64 del objeto JSON para no mostrarla en la visualización
            delete obj[key];
          } else if (typeof obj[key] === 'object') {
            findBase64Images(obj[key]);
          }
        }
      };

      findBase64Images(json);
    }
    ,
    selectFile() {
      this.$refs.fileInput.click();
    },
    handleImageClick(event, matricula) {
      // Verificar si Ctrl y Shift están presionados
      if (event.ctrlKey && event.shiftKey) {
        this.downloadImage(event.target.src, matricula);
      } else {
      this.showMosaic = !this.showMosaic;
      }
    },
    downloadImage(imageSrc, matricula) {
      const link = document.createElement('a');
      link.href = imageSrc;
      link.download = `${matricula}.jpg`; // Guardar la imagen con el nombre de la matrícula
      link.click();
    },
  },
};
</script>

<style scoped>
.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  cursor: pointer;
  background-color: #f9f9f9;
}

button {
  margin-top: 20px;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 10px 0;
}
</style>
