<template>
  <div id="app">
    <JsonViewer />
  </div>
</template>

<script>
import JsonViewer from './components/JsonViewer.vue';
import 'bootstrap/dist/css/bootstrap.min.css';


export default {
  name: 'App',
  components: {
    JsonViewer,
  },
};
</script>
