<template>
  <div class="image-mosaic-container">
    <div class="row g-0">
      <div
        v-for="(image, index) in base64Images"
        :key="index"
        class="col-6 col-md-4 col-lg-3"
      >
        <img
          :src="image.src"
          alt="Imagen Base64"
          class="img-fluid w-100 h-100"
          @click="handleImageClick($event, image.matricula)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    base64Images: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleImageClick(event, matricula) {
      // Verificar si Ctrl y Shift están presionados
      if (event.ctrlKey && event.shiftKey) {
        this.downloadImage(event.target.src, matricula);
      }
    },
    downloadImage(imageSrc, matricula) {
      const link = document.createElement('a');
      link.href = imageSrc;
      link.download = `${matricula}.jpg`; // Guardar la imagen con el nombre de la matrícula
      link.click();
    },
  },
};
</script>

<style scoped>
.image-mosaic-container {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-fluid {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
</style>
